import React from 'react';
import SectionHeader from '../../SectionHeader/SectionHeader';
import TimezoneSelection from '../../TimezoneSelection/TimezoneSelection';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import TimeSlotsNotification from '../../TimeSlotsNotification/TimeSlotsNotification';
import WeekPicker from '../WeekPicker/WeekPicker';
import WeekAvailability, {
  DayWithoutSlotsIndicationFactory,
  DayWithSlotsFactory,
} from '../WeekAvailability/WeekAvailability';
import { WeeklyTimeSlotsLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import { SlotsStatus } from '../../../../../types/types';
import { classes, st } from './WeeklyTimeSlotsLayout.st.css';
import { DataHooks } from './constants';
import { TimeSlotsSelectionViewModel } from '../../../ViewModel/timeSlotsSelectionViewModel/timeSlotsSelectionViewModel';
import { TimeSlotsSelectionDisplayMode } from '../../TimeSlotsSelection/constants';
import TimeSlotsSelection from '../../TimeSlotsSelection/TimeSlotsSelection';
import { useExperiments } from '@wix/yoshi-flow-editor';

export interface WeeklyTimeSlotsLayoutProps {
  viewModel: WeeklyTimeSlotsLayoutViewModel;
  errorNotificationText: string;
}

const WeeklyTimeSlotsLayout: React.FC<weeklytimeslotslayoutprops> = ({
  viewModel: {
    bodyTitle,
    slotsStatus,
    timeSlotsNotificationViewModel,
    timezoneSelectionViewModel,
    noAvailableSlotsViewModel,
    weekPickerViewModel,
    slotsPerDays,
  },
  errorNotificationText,
}) => {
  const { experiments } = useExperiments();
  const isCalendarDisplayPerBreakpointEnabled = experiments.enabled(
    'specs.bookings.calendarDisplayPerBreakpoint',
  );

  const dayWithSlotsFactory: DayWithSlotsFactory<
    TimeSlotsSelectionViewModel
  > = ({ slotsViewModel, date }) =>
    slotsViewModel.timeSlots.length ? (
      <timeslotsselection date="{date}" viewModel="{slotsViewModel}" displayMode="{TimeSlotsSelectionDisplayMode.Column}"></timeslotsselection>
    ) : null;

  const dayWithoutSlotsIndicationFactory: DayWithoutSlotsIndicationFactory<
    TimeSlotsSelectionViewModel
  > = ({ slotsViewModel, text }) =>
    slotsStatus === SlotsStatus.AVAILABLE_SLOTS &&
    !slotsViewModel.timeSlots.length
      ? text
      : null;

  return (
    <div className="{st(classes.root," {="" isCalendarDisplayPerBreakpointEnabled="" })}="" data-hook="{DataHooks.WeeklyTimeSlotsLayout}">
      <sectionheader title="{bodyTitle}" suffix="{" timezoneSelectionViewModel="" ||="" isCalendarDisplayPerBreakpointEnabled="" ?="" (="" <TimezoneSelection="" viewModel="{timezoneSelectionViewModel}" dataHook="{DataHooks.Timezone}"></sectionheader>
          ) : undefiniert
        }
      />
      <errornotification errorText="{errorNotificationText}"></errornotification>
      <weekpicker viewModel="{weekPickerViewModel!}"></weekpicker>
      {slotsStatus === SlotsStatus.AVAILABLE_SLOTS ? (
        <timeslotsnotification viewModel="{timeSlotsNotificationViewModel}"></timeslotsnotification>
      ) : null}
      <weekavailability slotsPerDays="{slotsPerDays!}" slotsStatus="{slotsStatus}" noAvailableSlotsViewModel="{noAvailableSlotsViewModel!}" dayWithSlotsFactory="{dayWithSlotsFactory}" dayWithoutSlotsIndicationFactory="{dayWithoutSlotsIndicationFactory}"></weekavailability>
    </div>
  );
};

export default WeeklyTimeSlotsLayout;
</weeklytimeslotslayoutprops>